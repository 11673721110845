import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Message = ({ text, sender, timestamp, url }) => {
  const storedMode = localStorage.getItem('darkMode') === 'true';
  const [darkMode, setDarkMode] = useState(storedMode);

  useEffect(() => {
    // Update dark mode state from local storage
    setDarkMode(storedMode);
  }, [storedMode]);

  const renderHTML = (html) => {
    return { __html: html };
  };

  return (
    <div className={`message ${sender} ${darkMode ? 'dark' : 'light'}`}>
      {sender === 'receipt' ? (
       <>
       <b>Transaction Successful</b>
       <div className="pdf-template">
         <a href={text} target="_blank" download>
           <div className="pdf-icon " align="center" style={{ color: 'red', fontSize: '50px' }}>
             <FontAwesomeIcon icon={faFilePdf} />
           </div>
         </a>
       </div>
     </>
      ) : (
        <div className="message-content" dangerouslySetInnerHTML={renderHTML(text)} />
      )}
      <div className="message-timestamp">{timestamp}</div>
    </div>
  );
};

export default Message;
