import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import Message from './Message';
import UserInput from './UserInput';

const ChatWindow = ({ darkMode }) => {
  const [messages, setMessages] = useState([]);
  const chatRef = useRef(null);
  const socketRef = useRef(null);
  const messageQueueRef = useRef([]);

  const generateRandomDigits = () => {
    let randomDigits = '';
    for (let i = 0; i < 12; i++) {
      const digit = Math.floor(Math.random() * 10);
      randomDigits += digit;
    }
    const checkId = localStorage.getItem('userId');
    if (checkId) {
      return checkId;
    } else {
      
      localStorage.setItem('userId', randomDigits);
      return randomDigits;
    }
  };
  const userId = generateRandomDigits();

  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem('chatMessages')) || [];
    setMessages(storedMessages);

    chatRef.current.scrollTop = chatRef.current.scrollHeight;

    socketRef.current = io.connect('https://chat.paysnug.link');

    socketRef.current.on('connect', () => {
      socketRef.current.emit('join', userId);
      while (messageQueueRef.current.length > 0) {
        const message = messageQueueRef.current.shift();
        sendMessage(message);
      }
    });
    socketRef.current.on('receipt', (newMessage, messageId) => {
      if (newMessage.trim() !== '') { // Check if the response is not empty
        addMessage(newMessage, "receipt");
      }
      socketRef.current.emit('ack', messageId); // Send acknowledgment
    });
    
    socketRef.current.on('newResponse', (newMessage, messageId) => {
      if (newMessage.trim() !== '') { // Check if the response is not empty
        addMessage(newMessage, "bot");
      }
      socketRef.current.emit('ack', messageId); // Send acknowledgment
    });

    socketRef.current.on('response', (newMessage, messageId) => {
      if (newMessage.trim() !== '') { // Check if the response is not empty
        addMessage(newMessage, "bot");
      }
      socketRef.current.emit('ack', messageId); // Send acknowledgment
    });
    socketRef.current.on('disconnect', () => {
      console.log('Disconnected from server');
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages]);

  const addMessage = (text, sender) => {
    const newMessage = { text, sender, timestamp: new Date().toLocaleTimeString() };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const sendMessage = (userMessage) => {
    if (navigator.onLine) { // Check if the network is available
      addMessage(userMessage, 'user'); // Add the message
      socketRef.current.emit('message', { message: userMessage, userId: userId, someOtherData: 'jsjsjs' });
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    } else {
      console.log('No network connection. Message not sent.');
    }
  };
  

  const handleUserMessage = (userMessage) => {
    messageQueueRef.current.push(userMessage);
    sendMessage(userMessage);
  };

  return (
    <div className="chat-window">
      <div className="messages" ref={chatRef}>
        {messages.map((message, index) => (
          <Message
            key={index}
            text={message.text}
            sender={message.sender}
            timestamp={message.timestamp}
            darkMode={darkMode}
          />
        ))}
      </div>
      <br />
      <br />
      <br />
      <div className="user-input-fixed">
        <UserInput addMessage={handleUserMessage} darkMode={darkMode} />
      </div>
    </div>
  );
};

export default ChatWindow;
