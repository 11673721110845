import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
const UserInput = ({ addMessage }) => {
  const [inputText, setInputText] = useState('');

  // Get dark mode preference from local storage
  const storedMode = localStorage.getItem('darkMode') === 'true';
  const [darkMode, setDarkMode] = useState(storedMode);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputText.trim() !== '') {
      addMessage(inputText, 'user');
      setInputText('');
    }
  };

  useEffect(() => {
    // Update dark mode state from local storage
    setDarkMode(storedMode);
  }, [storedMode]);

  return (
    <div className={`user-input ${darkMode ? 'dark' : 'light'}`}>
      <form onSubmit={handleSubmit}>
        <input
          className={`input ${darkMode ? 'dark' : 'light'}`}
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Type your message..."
        />
       <button type="submit">
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{
              color: darkMode ? 'white' : 'rgb(25, 25, 112)',
              fontSize: '1.5rem',
            }}
          />
        </button>
      </form>
    </div>
  );
};

export default UserInput;
