import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ChatWindow from './ChatWindow';
import { FaMoon, FaSun } from 'react-icons/fa'; 
const ChatApp = () => {
  const storedMode = localStorage.getItem('darkMode') === 'true';
  const [darkMode, setDarkMode] = useState(storedMode);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode); // Save to local storage
  };

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode); // Update local storage when darkMode changes
  }, [darkMode]);

  return (
    <div className={`app ${darkMode ? 'dark' : 'light'}`}>
      <nav className={`navbar navbar-expand-lg ${darkMode ? 'navbar-dark bg-dark' : 'navbar-light bg-light'} shadow fixed-top`}>
      <img className={`logo ${darkMode ? 'dark-logo' : 'light-logo'}`} src={darkMode ? 'https://ik.imagekit.io/paysnug/web%20asset/psgbotwhite.png?updatedAt=1693391910564' : 'https://ik.imagekit.io/paysnug/web%20asset/psgbotcolor.png?updatedAt=1693391756901'} alt="Paysnug" />
        <div className="navbar-nav ml-auto mode-toggle" onClick={toggleDarkMode}>
        {darkMode ? <FaSun size={20}/> : <FaMoon size={20} />}
        </div>
      </nav>
      <ChatWindow darkMode={darkMode} />
    </div>
  );
};

export default ChatApp;
